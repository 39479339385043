import React, { createContext, useState, useContext, ReactNode, useEffect, useCallback, useRef } from 'react';
import { Booking } from '../../../interfaces';
import { ACTIVE_URL } from "../../constants";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../../@/components/ui/dialog";
import { Button } from "../../@/components/ui/button";
import { IonIcon } from '@ionic/react';
import { Label } from '../../@/components/ui/label';
import { Input } from '../../@/components/ui/input';

interface ReservationsContextType {
    driverId: number | null;
    numberOfReservations: number;
    reservations: Booking[];
    fetchReservations: () => void;
    makeReservation: (bookingId: number) => Promise<void>;
    confirmCancelReservation: (bookingId: number) => void;
    availableBookings: Booking[];
    hasActiveRestriction: boolean;
}

const ReservationsContext = createContext<ReservationsContextType | undefined>(undefined);

export const useReservations = (): ReservationsContextType => {
    const context = useContext(ReservationsContext);
    if (context === undefined) {
        throw new Error('useReservations must be used within a ReservationsProvider');
    }
    return context;
};

export const useSafeReservations = (): ReservationsContextType | null => {
    const context = useContext(ReservationsContext);
    return context || null;
};

interface ReservationsProviderProps {
    children: ReactNode;
    driverId: number | null;
}

export const ReservationsProvider: React.FC<ReservationsProviderProps> = ({ children, driverId }) => {
    const [reservations, setReservations] = useState<Booking[]>([]);
    const [numberOfReservations, setNumberOfReservations] = useState<number>(0);
    const [availableBookings, setAvailableBookings] = useState<Booking[]>([]);
    const [hasActiveRestriction, setHasActiveRestriction] = useState<boolean>(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [bookingToCancel, setBookingToCancel] = useState<number | null>(null);
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const [isConnecting, setIsConnecting] = useState(false);
    const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [connectionAttempts, setConnectionAttempts] = useState(0);
    const maxConnectionAttempts = 5;
    const [cancellationReason, setCancellationReason] = useState('');
    
    const connectWebSocket = useCallback(() => {
        if (isConnecting || !driverId || connectionAttempts >= maxConnectionAttempts) return;

        setIsConnecting(true);
        let wsUrl;

        if (ACTIVE_URL.includes('localhost') || ACTIVE_URL.includes('127.0.0.1')) {
            wsUrl = `ws://${ACTIVE_URL.replace(/^https?:\/\//, '')}/ws/active_bookings/`;
        } else {
            const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
            wsUrl = `${wsProtocol}//${window.location.host}/ws/active_bookings/`;
        }

        console.log(`Attempting to connect to WebSocket: ${wsUrl}`);

        const newSocket = new WebSocket(wsUrl);

        const connectionTimeout = setTimeout(() => {
            console.error('WebSocket connection attempt timed out');
            newSocket.close();
        }, 1000); // 10 seconds timeout

        newSocket.onopen = () => {
            clearTimeout(connectionTimeout);
            console.log('WebSocket connected successfully');
            setSocket(newSocket);
            setIsConnecting(false);
            setConnectionAttempts(0);
            newSocket.send(JSON.stringify({ 
                type: 'fetch_active_bookings',
                driver_id: driverId
            }));
            newSocket.send(JSON.stringify({
                type: 'fetch_my_bookings',
                driver_id: driverId
            }));
        };

        newSocket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log('Received WebSocket message:', data);
            if (data.type === 'active_bookings') {

                setAvailableBookings(data.bookings);
                setHasActiveRestriction(data.bookings.has_active_restriction);
                newSocket.send(JSON.stringify({
                    type: 'fetch_my_bookings',
                    driver_id: driverId
                }));
            } else if (data.type === 'my_bookings') {
                if (Array.isArray(data.bookings)) {
                    setReservations(data.bookings);
                    setNumberOfReservations(data.bookings.length);
                } else {
                    console.error('Received bookings data is not an array');
                }
            } else if (data.type === 'refresh_my_bookings') {
                fetchReservations();
            }
        };

        newSocket.onerror = (error) => {
            console.error('WebSocket error:', error);
            setIsConnecting(false);
            setConnectionAttempts(prev => prev + 1);
        };

        newSocket.onclose = (event) => {
            console.log('WebSocket closed. Attempting to reconnect...');
            setSocket(null);
            setIsConnecting(false);
            setConnectionAttempts(prev => prev + 1);

            if (connectionAttempts < maxConnectionAttempts) {
                reconnectTimeoutRef.current = setTimeout(() => {
                    connectWebSocket();
                }, 5000 * (connectionAttempts + 1));
            } else {
                console.error('Max reconnection attempts reached. Please refresh the page.');
            }
        };

        return () => {
            clearTimeout(connectionTimeout);
        };
    }, [driverId, connectionAttempts]);

    useEffect(() => {
        if (driverId) {
            connectWebSocket();
        } else {
            // Reset states when there's no driver ID
            setReservations([]);
            setNumberOfReservations(0);
            setAvailableBookings([]);
            setHasActiveRestriction(false);
            setConnectionAttempts(0);
            if (socket) {
                socket.close();
            }
        }

        return () => {
            if (socket) {
                socket.close();
            }
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
        };
    }, [driverId, connectWebSocket]);

    const fetchReservations = useCallback(() => {
        if (socket && socket.readyState === WebSocket.OPEN && driverId) {
            socket.send(JSON.stringify({
                type: 'fetch_my_bookings',
                driver_id: driverId
            }));
        } else if (!driverId) {
            console.log('No driver ID available to fetch reservations');
        } else {
            console.error(`Cannot fetch reservations. Socket state: ${socket ? socket.readyState : 'null'}`);
        }
    }, [socket, driverId]);

    const makeReservation = async (bookingId: number): Promise<void> => {
        if (!driverId) {
            console.error('Cannot make reservation: No driver ID available');
            return;
        }

        try {
            const response = await fetch(`${ACTIVE_URL}/api/reserve_booking/${bookingId}/${driverId}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.ok) {
                await fetchReservations();
                if (socket && socket.readyState === WebSocket.OPEN) {
                    socket.send(JSON.stringify({ type: 'booking_updated' }));
                }
                setAvailableBookings(prevAvailable => prevAvailable.filter(booking => parseInt(booking.id) !== bookingId));
            } else {
                console.error('Failed to make reservation');
                throw new Error('Failed to make reservation');
            }
        } catch (error) {
            console.error('Error making reservation:', error);
            throw error;
        }
    };

    const cancelReservation = async (bookingId: number, reason: string) => {
        try {
            const response = await fetch(`${ACTIVE_URL}/api/cancel_reservation/${bookingId}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ reason })
            });
            if (response.ok) {
                setReservations(prevReservations => prevReservations.filter(reservation => parseInt(reservation.id) !== bookingId));
                setNumberOfReservations(prev => prev - 1);
                if (socket && socket.readyState === WebSocket.OPEN) {
                    socket.send(JSON.stringify({ type: 'booking_updated' }));
                }
            } else {
                console.error('Failed to cancel reservation');
                throw new Error('Failed to cancel reservation');
            }
        } catch (error) {
            console.error('Error cancelling reservation:', error);
            throw error;
        }
    };

    const confirmCancelReservation = (bookingId: number) => {
        setBookingToCancel(bookingId);
        setCancellationReason('');
        setIsConfirmOpen(true);
    };


    const handleConfirmCancel = async () => {
        if (bookingToCancel !== null) {
            try {
                await cancelReservation(bookingToCancel, cancellationReason);
                setIsConfirmOpen(false);
                setBookingToCancel(null);
                setCancellationReason('');
            } catch (error) {
                console.error('Error during reservation cancellation:', error);
            }
        }
    };

    return (
        <ReservationsContext.Provider value={{
            driverId,
            numberOfReservations,
            reservations,
            availableBookings,
            fetchReservations,
            makeReservation,
            confirmCancelReservation,
            hasActiveRestriction
        }}>
            {children}
            <Dialog open={isConfirmOpen} onOpenChange={setIsConfirmOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle className='text-red-500 dark:text-red-500 mb-1'>Επιβεβαίωση Ακύρωσης</DialogTitle>
                        <DialogDescription>
                            Είστε σίγουροι ότι θέλετε να ακυρώσετε αυτή την κράτηση; Ενδέχεται να υπάρξουν κυρώσεις για την ακύρωση.
                        </DialogDescription>
                    </DialogHeader>
                    <div className="grid w-full items-center gap-4 my-1">
                        <div className="flex flex-col space-y-1.5">
                            <Label htmlFor="cancellation-reason" className='text-center mb-1'>Λόγος Ακύρωσης</Label>
                            <Input
                                id="cancellation-reason"
                                placeholder="Εισάγετε τον λόγο ακύρωσης"
                                className='border-red-500 focus:outline-none focus:ring-none'
                                value={cancellationReason}
                                onChange={(e) => setCancellationReason(e.target.value)}
                                autoFocus
                            />
                        </div>
                    </div>
                    <DialogFooter className='flex gap-2 sm:gap-0'>
                        <Button variant="secondary" className='dark:bg-neutral-500 dark:text-neutral-100' onClick={() => setIsConfirmOpen(false)}>Άκυρο</Button>
                        <Button variant="destructive" onClick={handleConfirmCancel}>
                            <IonIcon icon='warning-outline' className='mr-1' />
                            Επιβεβαίωση Ακύρωσης
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </ReservationsContext.Provider>
    );
};