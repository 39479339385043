import React from "react";
import { Booking } from "../../../../interfaces";
import { IonIcon } from "@ionic/react";
import { ACTIVE_URL } from "../../../constants";
import { useReservations } from '../../../utils/ReservationsContext/ReservationsContext';
import {
    Drawer,
    DrawerClose,
    DrawerContent,
    DrawerDescription,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from "../../../@/components/ui/drawer";
import GoogleMap from "../../../utils/GoogleMap/GoogleMap";
import CountdownTimer from "../../../utils/CountDown/CountDown";


interface DriverReservationsItemProps {
    booking: Booking;
}

const DriverReservationsItem: React.FC<DriverReservationsItemProps> = ({
    booking
}) => {
    const { driverId, reservations, numberOfReservations, fetchReservations, makeReservation, confirmCancelReservation, availableBookings } = useReservations();

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('el-GR', { day: '2-digit', month: '2-digit', year: '2-digit' });
    };

    const formatTime = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleTimeString('el-GR', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
    };

    const formatCountdown = (targetDate: string) => {
        const now = new Date();
        const target = new Date(targetDate);
        const difference = target.getTime() - now.getTime();

        if (difference > 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

            if (days > 0) {
                return `Σε ${days} ${days === 1 ? 'μέρα' : 'μέρες'}`;
            } else if (hours > 0) {
                return `Σε ${hours} ${hours === 1 ? 'ώρα' : 'ώρες'}`;
            } else {
                return 'Σε λιγότερο από μία ώρα';
            }
        } else {
            return 'Έφτασε';
        }
    };

    console.log(booking.status)

    return (
        <>
            <div className={`lg:hidden bg-neutral-100 min-h-10 w-full rounded-md shadow-lg px-2.5 py-2.5 text-neutral-800 text-lg flex flex-col gap-2 border mx-4 dark:bg-neutral-900 dark:text-neutral-100 dark:border-neutral-500 ${booking.status === 'ONGOING' ? 'border-main dark:border-main' : ''}`} >
                <div className="flex justify-between items-center w-full">
                    <div className='text-[1.06rem]'>{formatDate(booking.pickup_time)} {formatTime(booking.pickup_time)}</div>
                    <CountdownTimer targetDate={booking.pickup_time} />
                </div>
                <div className="my-1 flex flex-col gap-1">

                    <div className="flex gap-2 items-center justify-start text-base">

                        <div className='w-4 min-w-4'>
                            <IonIcon icon='location' />
                        </div>
                        <div>{booking.pickup_address}</div>
                    </div>
                    <div className="flex gap-2 items-center justify-start text-base">
                        <div className='w-4 min-w-4'>
                            <IonIcon icon='navigate' className="z-0" />
                        </div>

                        <div>{booking.dropoff_address}</div>
                    </div>
                </div>
                <div className={`w-full grid ${booking.status === 'ONGOING' ? 'grid-cols-1' : 'grid-cols-2'}  gap-2`}>
                    {
                        booking.status === 'ONGOING' ? (
                            <></>
                        ) : (
                            <div
                                className="bg-neutral-500 text-white text-sm rounded-md px-2 text-center py-1.5 cursor-pointer"
                                onClick={() => confirmCancelReservation(parseInt(booking.id, 10))}
                            >
                                Ακύρωση
                            </div>
                        )
                    }

                    <Drawer>
                        <DrawerTrigger className="bg-main text-white text-sm rounded-md px-2 text-center py-1.5 cursor-pointer">
                            Λεπτομέρειες
                        </DrawerTrigger>
                        <DrawerContent className="max-h-[80vh] ">
                            <DrawerHeader>
                                <DrawerTitle className="mb-2 mt-1 dark:text-neutral-200">Λεπτομέρειες Κράτησης</DrawerTitle>
                                <DrawerDescription className="w-full flex flex-col justify-start items-start gap-3">
                                    <div className="text-center w-full">{formatDate(booking.pickup_time)} {formatTime(booking.pickup_time)}</div>

                                    <div className="flex gap-1">
                                        <div className="font-semibold flex items-center justify-center"> <IonIcon icon='time-outline' className="mr-1" />Παραλαβή σε:</div>
                                        <div><CountdownTimer targetDate={booking.pickup_time} /></div>
                                    </div>
                                    <div className="flex gap-1">
                                        <div className="font-semibold flex items-center justify-center"> <IonIcon icon='time-outline' className="mr-1" />Τύπος οχήματος:</div>
                                        <div>{booking.compatible_vehicle_types[0].name}</div>
                                    </div>
                                    <div className="flex gap-1 items-center justify-center">
                                        <div className="font-semibold flex items-center justify-center"><IonIcon icon='location' className="mr-1" />Σημείο Παραλαβής:</div>
                                        <div>{booking.pickup_address}</div>
                                    </div>
                                    <div className="flex gap-1 items-center justify-center">
                                        <div className="font-semibold flex items-center justify-center"><IonIcon icon='navigate' className="mr-1 " />Προορισμός:</div>
                                        <div>{booking.dropoff_address}</div>
                                    </div>
                                    <div className="flex gap-1 items-center justify-center">
                                        <div className="font-semibold flex items-center justify-center"><IonIcon icon='person' className="mr-1" />Όνομα επιβάτη:</div>
                                        <div>{booking.passenger_name}</div>
                                    </div>
                                    {booking.message && booking.message != '' && booking.message != 'Not provided' && (
                                        <div className="flex gap-1 items-center justify-center">
                                            <div className="font-semibold flex items-center justify-center"><IonIcon icon='person' className="mr-1" />Σημείωση Πελάτη</div>
                                            <div>{booking.message}</div>
                                        </div>
                                    )}

                                </DrawerDescription>
                            </DrawerHeader>
                            <DrawerFooter>
                                <DrawerClose className="bg-main rounded-md py-2 text-lg text-white">
                                    Κλείσιμο
                                </DrawerClose>
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                </div>
            </div>
            <div className="hidden lg:flex px-2 mx-4 h-12 w-full border border-neutral-300 rounded-lg cursor-pointer items-center justify-start gap-4 max-w-desktop">
                <div className="w-[15%]">
                    <CountdownTimer targetDate={booking.pickup_time} />
                </div>
                <div className="w-[15%]">
                    {formatDate(booking.pickup_time)}
                </div>
                <div className="w-[15%]">
                    {formatTime(booking.pickup_time)}
                </div>
                <div className="w-[20%]">
                    {booking.pickup_address}
                </div>
                <div className="w-[20%]">
                    {booking.dropoff_address}
                </div>
                <div className="flex gap-2">
                    <Drawer>
                        <DrawerTrigger className="bg-main text-white text-sm rounded-md px-2 text-center py-1.5 cursor-pointer">
                            Λεπτομέρειες
                        </DrawerTrigger>
                        <DrawerContent className="">
                            <DrawerHeader>
                                <DrawerTitle className="mb-2 mt-1 text-center dark:text-neutral-200">Λεπτομέρειες Κράτησης</DrawerTitle>
                                <DrawerDescription className="w-full flex flex-col justify-start items-start gap-3">
                                    <div className="text-center w-full">{formatDate(booking.pickup_time)} {formatTime(booking.pickup_time)}</div>
                                    <div className="flex gap-1">
                                        <div className="font-semibold flex items-center justify-center"> <IonIcon icon='time-outline' className="mr-1" />Χρόνος Παραλαβής:</div>
                                        <div><CountdownTimer targetDate={booking.pickup_time} /></div>
                                    </div>
                                    <div className="flex gap-1 items-center justify-center">
                                        <div className="font-semibold flex items-center justify-center"><IonIcon icon='location' className="mr-1" />Σημείο Παραλαβής:</div>
                                        <div>{booking.pickup_address}</div>
                                    </div>
                                    <div className="flex gap-1 items-center justify-center">
                                        <div className="font-semibold flex items-center justify-center"><IonIcon icon='navigate' className="mr-1 " />Προορισμός:</div>
                                        <div>{booking.dropoff_address}</div>
                                    </div>
                                    <div className="flex gap-1 items-center justify-center">
                                        <div className="font-semibold flex items-center justify-center"><IonIcon icon='person' className="mr-1" />Όνομα επιβάτη:</div>
                                        <div>{booking.passenger_name}</div>
                                    </div>
                                </DrawerDescription>
                            </DrawerHeader>
                            <DrawerFooter>
                                <DrawerClose className="bg-main rounded-md py-2 text-lg text-white">
                                    Κλείσιμο
                                </DrawerClose>
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                    <div
                        className="bg-neutral-500 text-white text-sm rounded-md px-2 text-center py-1.5 cursor-pointer"
                        onClick={() => confirmCancelReservation(parseInt(booking.id, 10))}
                    >
                        Ακύρωση
                    </div>
                </div>
            </div>
        </>
    )
}

export default DriverReservationsItem;