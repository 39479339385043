import React from 'react';
import { IonIcon } from "@ionic/react";
import { Booking } from "../../../../interfaces";
import { useReservations } from "../../../utils/ReservationsContext/ReservationsContext";
import CountdownTimer from '../../../utils/CountDown/CountDown';

interface DriverBookingItemProps {
    booking: Booking;
}

const AllPreBookingsItem: React.FC<DriverBookingItemProps> = ({
    booking
}) => {
    const { driverId, makeReservation } = useReservations();
    console.log(booking)
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('el-GR', { day: '2-digit', month: '2-digit', year: '2-digit' });
    };

    const formatTime = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleTimeString('el-GR', { 
            hour: '2-digit', 
            minute: '2-digit', 
            hour12: false 
        });
    };

    return (
        <>
            <div className="lg:hidden bg-neutral-100 min-h-10 w-full rounded-md shadow-lg px-2.5 py-2.5 text-neutral-800 text-lg flex flex-col gap-2 border mx-4 dark:bg-neutral-900 dark:text-neutral-100 dark:border-neutral-500">
                <div className="flex justify-between items-center w-full">
                    <div className='text-[1.06rem]'>{formatDate(booking.pickup_time)} {formatTime(booking.pickup_time)}</div>
                    <CountdownTimer targetDate={booking.pickup_time} />
                </div>
                <div className="my-1 flex flex-col gap-1 ">
                    <div className="flex gap-2 items-center justify-start text-base">
                        <div className='w-4 min-w-4'>
                            <IonIcon icon='location' />
                        </div>

                        <div className='text-base'>{booking.general_pickup_address}</div>
                    </div>
                    <div className="flex gap-2 items-center justify-start text-base">
                        <div className='w-4 min-w-4'>
                            <IonIcon icon='navigate' />
                        </div>

                        <div>{booking.general_pickup_dropoff}</div>
                    </div>
                </div>
                <div className="w-full grid grid-cols-1 gap-2">
                    <div
                        className="bg-main text-white text-sm rounded-md px-2 text-center py-1.5 cursor-pointer"
                        onClick={() => makeReservation(parseInt(booking.id, 10))}
                    >
                        Κράτηση
                    </div>
                </div>
            </div>
            <div className="hidden lg:flex px-2 mx-4 h-12 w-full border border-neutral-300 dark:border-neutral-500 rounded-lg cursor-pointer items-center justify-start gap-2.5 max-w-desktop dark:bg-neutral-900">
                <div className="w-[15%]">
                    <CountdownTimer targetDate={booking.pickup_time} />
                </div>
                <div className="w-[15%]">
                    {formatDate(booking.pickup_time)}
                </div>
                <div className="w-[15%]">
                    {formatTime(booking.pickup_time)}
                </div>
                <div className="w-[20%]">
                    {booking.general_pickup_address}
                </div>
                <div className="w-[20%]">
                    {booking.general_pickup_dropoff}
                </div>
                <div>
                    <div
                        className="bg-main text-white text-sm rounded-md px-2 text-center py-1.5 cursor-pointer"
                        onClick={() => makeReservation(parseInt(booking.id, 10))}
                    >
                        Κράτηση
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllPreBookingsItem;