import React from 'react';
import AllBookingsHeader from '../../Components/BookingHeader/BookingHeader';
import { Booking } from '../../../interfaces';
import { useReservations } from '../../utils/ReservationsContext/ReservationsContext';
import AllPreBookingsItem from './AllPreBookingsItem/AllPreBookingsItem';
import { sortBookingsByPickupTime } from '../../utils/BookingSortByPickup/BookingSortByPickup';

const AllPreBookings: React.FC = () => {
    const { availableBookings, hasActiveRestriction } = useReservations();

    const sortedBookings = Array.isArray(availableBookings) 
        ? sortBookingsByPickupTime(availableBookings) 
        : [];

    return (
        <div className=''>
            <div className='max-w-desktop m-auto my-4'>
                <div className='flex flex-col gap-2'>
                    <AllBookingsHeader />
                    {hasActiveRestriction ? (
                        <div className="min-h-[30vh] flex items-center justify-center px-4 text-center text-neutral-500 dark:text-neutral-500">
                            Έχετε ενεργό περιορισμό και δεν μπορείτε να δείτε διαθέσιμα ραντεβού.
                        </div>
                    ) : sortedBookings.length > 0 ? (
                        sortedBookings.map((booking: Booking) => (
                            <div className="w-full flex justify-center select-none mb-2" key={booking.id}>
                                <AllPreBookingsItem booking={booking} />
                            </div>
                        ))
                    ) : (
                        <div className="min-h-[30vh] flex items-center justify-center px-4 text-center text-neutral-500 dark:text-neutral-500">
                            Δεν υπάρχουν διαθέσιμα ραντεβού
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AllPreBookings;